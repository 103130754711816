.border-modal .modal-content {
  border: unset !important;
  background: transparent !important;
}

.modal-backdrop {
  /* background-color: #ffffff; */
  background-color: #000;
}

.loading {
  margin: auto;
}
.local-loading {
  margin: auto;
}
