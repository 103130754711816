* {
  padding: 0px;
  margin: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disabled {
  color: #6c757d !important;
  background-color: #fff !important;
}

.disabled:hover {
  color: #6c757d !important;
  background-color: #fff !important;
}

.bg-disabled, .bg-disabled__control {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}


.table thead tr {
  background: rgba(157, 157, 157, .05);
}

.table tbody tr:hover {
  background-color: rgba(157, 157, 157, .05);
}

.table-fixed {
  table-layout: fixed;
}

.Toastify__toast {
  font-family: 'Tajawal', sans-serif !important;
}

.Toastify__toast--error {
  background: #D33E43 !important;
}

.Toastify__toast--success {
  background: #379392 !important;
}

.ecp-bg-success {
  background: #379392;
}

.ecp-bg-danger {
  background: #D33E43;
}

[dir=rtl] .form-label {
  text-align: right;
}

[dir=rtl] .invalid-feedback {
  text-align: right;
}
