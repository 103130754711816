/* theme 
success #379392
error #d33e43
*/

body {
  background-color: #f5f6f8;
  --icons-color: #20409a;
  --icons-color-new: #ff660f;
  --icons-color-disable: #999999;
  --system-bg: #20409a;
  --system-btn: #f0f4f6;
  --system-color: #20409a;
  font-family: 'Tajawal', sans-serif !important;
}

::placeholder {
  color: #8d99a6;
  font-weight: bold;
  font-size: 0.9em;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-break-word {
  overflow-wrap: break-word;
}

.overflow-hidden {
  overflow: hidden;
}

.margin-important {
  margin: 10px !important;
}

.capitalize {
  text-transform: capitalize;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.content {
  flex-grow: 1;
  /* min-height: 89.5vh; */
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:checked + .btn-outline-success {
  color: #fff;
  background-color: #379392;
  border-color: #379392;
}

.btn-check:checked + .btn-outline-danger {
  color: #fff;
  background-color: #d33e43;
  border-color: #d33e43;
}

.btn-check:checked + .btn-outline-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn:not(label) > .btn-group {
  border: none !important;
  padding: 0.5rem 1rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.gap-0\.3 {
  gap: 0.3rem;
}

.btn-primary {
  /* background: linear-gradient(179.83deg, #20409A -10.06%, #21176b 106.57%); */
  /* background-size: 100% 81px; */
  background-color: var(--system-color);
  border-color: var(--system-color);
  border-radius: 6px;
  position: relative;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0px 0px 20px rgb(20 25 67 / 20%) !important;
  transition: box-shadow, background-color 0.5s ease !important;
}

.bg-primary {
  background-color: var(--system-color) !important;
}

.btn:disabled {
  cursor: not-allowed !important;
  background-color: var(--system-color);
  border-color: var(--system-color);
}

.text-primary {
  color: var(--system-color) !important;
}

.form-check-input:checked {
  color: var(--system-color);
  accent-color: currentcolor;
}

.btn-primary:not(:disabled):hover,
.react-confirm-alert-button-group button.btn-primary:hover {
  background-color: var(--system-color);
}

.btn-primary:not(:disabled):focus,
.btn-primary:not(:disabled):active,
.react-confirm-alert-button-group button.btn-primary:focus,
.react-confirm-alert-button-group button.btn-primary:active {
  background-color: var(--system-color);
}

.btn-secondary,
.react-confirm-alert-button-group button.btn-secondary {
  background-color: #d5dbdb;
  color: #000;
}

.react-confirm-alert-overlay {
  z-index: 1070;
}

.btn-secondary:hover,
.react-confirm-alert-button-group button.btn-secondary:hover {
  background-color: #bac4c4;
  color: #000;
}

.btn-secondary:focus,
.btn-secondary:active,
.react-confirm-alert-button-group button.btn-secondary:focus,
.react-confirm-alert-button-group button.btn-secondary:active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: #acb9b9;
  color: #000;
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--system-color);
  border-color: var(--system-color);
}

.btn-danger {
  background-color: #d33e43 !important;
}

.btn-danger:hover {
  background-color: #c32232;
}

.btn-danger:focus,
.btn-danger:active {
  background-color: #ad1f2d;
}

.btn-success {
  background-color: #379392;
}

.btn-success:hover {
  background-color: #23903c;
}

.btn-outline-success {
  color: #379392;
  border-color: #379392;
}

.btn-outline-success:hover {
  border-color: #379392;
  background-color: #379392;
  color: #ffffff;
}

.btn-outline-danger {
  color: #d33e43;
  border-color: #d33e43;
}

.btn-outline-danger:hover {
  border-color: #d33e43;
  background-color: #d33e43;
  color: #ffffff;
}

.btn-success:focus,
.btn-success:active {
  background-color: #1e7b34;
}

.btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-light {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.badge.bg-secondary {
  background-color: #d5dbdb !important;
}

.text-success {
  color: #379392 !important;
}

.text-error {
  color: #d33e43 !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.chip-success {
  background-color: #379392 !important;
  color: #fff !important;
}

.chip-danger {
  background-color: #379392 !important;
  color: #fff !important;
}

.bg-none {
  background-color: transparent !important;
}

.app-footer {
  border-top: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  color: #555;
  display: flex;
  /* gap: 1rem; */
}

.footer-separator {
  padding: 5px 0.5rem;
}

.footer-separator .line {
  border-left: solid 1px #ddd;
  height: 100%;
}

.app-footer a {
  text-decoration: none;
  color: var(--system-color);
}

.app-footer a:hover {
  text-decoration: underline;
}

.app-footer .powered {
  margin-top: 5px;
  font-size: 0.9em;
}

.app-footer .privacy-policy {
  border: 0;
  color: var(--system-color);
  background-color: transparent;
}

.app-footer .footer-language-selector,
.footer-language-option {
  font-family: 'Tajawal', sans-serif !important;
}

.app-footer .footer-language-selector {
  margin-bottom: 2px;
}

[dir='rtl'] .app-footer .footer-language-selector {
  margin-bottom: 5px;
}

.app-footer .footer-language-selector .MuiInput-input {
  padding-top: 0;
  padding-bottom: 0;
}

.app-footer .footer-language-selector .MuiSelect-icon {
  top: calc(50% - 17px);
}

.disabled-button {
  cursor: no-drop !important;
  background-color: #c7c7c8 !important;
  user-select: none !important;
}

.react-confirm-alert-button-group {
  justify-content: space-between !important;
}

.react-confirm-alert-button-group button {
  border: none !important;
  padding: 10px 30px !important;
  font-size: 0.8rem !important;
  margin: 0 !important;
}

/* .react-confirm-alert-button-group button:hover {
  opacity: 0.8;
} */
.react-confirm-alert-button-group button.btn-primary {
  /* background: linear-gradient(179.83deg, #20409A -10.06%, #21176b 106.57%); */
  background-color: var(--system-color);
  border-radius: 6px;
  background-size: 100% 81px;
  position: relative;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0px 0px 20px rgb(20 25 67 / 20%) !important;
  transition: box-shadow 0.5s ease !important;
}

.react-confirm-alert-button-group button.btn-secondary {
  /* background-color: #d5dbdb !important; */
  color: black;
  font-weight: bold;
}

.react-confirm-alert-body {
  color: #c32232;
}

.react-confirm-alert h1 {
  font-weight: bold;
  font-size: 22px;
  color: #333;
}

.rmdp-container > input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: initial;
  height: auto !important;
}

.rmdp-container {
  width: 100%;
}

.header-button .dropdown-toggle::after {
  display: none;
}

.header-button .btn-outline-secondary:focus,
.header-button
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.header-button .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.header-button .dropdown-item.active,
.header-button .dropdown-item:active {
  background-color: transparent;
  color: #212529;
}

.react-confirm-alert-body {
  font-family: 'Tajawal', sans-serif !important;
}

.react-confirm-alert__ltr .react-confirm-alert-body {
  text-align: left !important;
}

.react-confirm-alert__rtl .react-confirm-alert-body {
  text-align: right !important;
}

/* override bootstrap pagination style */
.page-item {
  margin: 0 0.2rem;
}

.page-item.disabled .page-link {
  background-color: #eee;
}

.page-link {
  background-color: transparent;
  border: none;
}

.basic-single {
  width: 100%;
}

.select__option--is-selected {
  background-color: var(--system-color) !important;
}

.border-red {
  border-color: #dc3545;
}

.ecp-table.grey-header th {
  background-color: rgb(245, 246, 248);
}
.MuiButtonBase-root.IconButton {
  min-width: 220px;
  font-family: 'Tajawal', sans-serif !important;
}

.MuiButtonBase-root.IconButton.BackButton {
  background-color: rgb(213, 219, 219);
  border-color: rgb(108, 117, 125);
  color: rgb(33, 37, 41)
}
.MuiButtonBase-root.IconButton.BackButton:hover {
  background-color: '#bac4c4'
}

.MuiButtonBase-root.IconButton.NextButton:not(.Mui-disabled) {
  background-color: rgb(32, 64, 154);
  border-color: rgb(10, 88, 202);
}

.MuiButtonBase-root.IconButton.NextButton:not(.Mui-disabled) {
  background-color: rgb(32, 64, 154);
  border-color: rgb(10, 88, 202);
}

.MuiButtonBase-root.IconButton.DeclineButton:not(.Mui-disabled) {
  background-color: #d33e43;
  border-color: #d33e43;
}

.MuiButtonBase-root.IconButton.AcceptButton:not(.Mui-disabled) {
  background-color: #23903c;
  border-color: #23903c;
}

[dir='rtl'] .MuiButtonBase-root.IconButton.BackButton .MuiButton-startIcon {
  margin-left: 8px !important;
  margin-right: -4px !important;
}

[dir='rtl'] .MuiButtonBase-root.IconButton.NextButton .MuiButton-endIcon {
  margin-right: 8px !important;
  margin-left: -4px !important;
}

[dir='rtl'] .MuiPagination-ul li:first-child,
[dir='rtl'] .MuiPagination-ul li:nth-child(2),
[dir='rtl'] .MuiPagination-ul li:nth-last-child(2),
[dir='rtl'] .MuiPagination-ul li:last-child {
  transform: rotate(180deg);
}

[dir='rtl'] .ecp-table th {
  text-align: right;
}

[dir='rtl'] .ecp-table tr td {
  text-align: right;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.internet_notification {
  width: 90% !important;
}

.zindex-popover {
  z-index: 1070;
}

.custom-file-label {
  text-align: right;
}

[dir='ltr'] .card .card-body .icon-box {
  margin-left: auto !important;
}

[dir='rtl'] .card .card-body .icon-box {
  margin-right: auto !important;
}

.tos-dialog {
  font-family: 'Tajawal', sans-serif !important;
}

.settings-value-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}